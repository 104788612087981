import http from '@/utils/http';

// 获取阶段列表
export const getTournament = (params) => {
    return http.request({
        url: '/v1/ballsports/base/tournament/list',
        method: 'get',
        params: params
    })
}

// 新增阶段的关联
export const addTournamentRelation = (params) => {
    return http.request({
        url: '/v1/ballsports/base/tournament/relation/add',
        method: 'post',
        data: params
    })
}

// 删除阶段的关联
export const delTournamentRelation = (params) => {
    return http.request({
        url: '/v1/ballsports/base/tournament/relation/del',
        method: 'post',
        data: params
    })
}

// 获取阶段的所有源
export const getTournamentSources = (params) => {
    return http.request({
        url: '/v1/ballsports/base/tournament/source/list',
        method: 'get',
        params: params
    })
}

// 获取阶段分类列表
export const getTournamentCategory = (params) => {
    return http.request({
        url: '/v1/ballsports/base/tournament/category/list',
        method: 'get',
        params: params
    })
}

// 搜索
export const searchTournament = (params) => {
    return http.request({
        url: '/v1/ballsports/base/tournament/search',
        method: 'post',
        data: params
    })
}

// 更新比赛
export const updateTournament = (params) => {
    return http.request({
        url: "/v1/ballsports/base/tournament/update",
        method: 'post',
        data: params,
    })
}