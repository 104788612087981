<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Form label-position="left" :label-width="80" >
            <FormItem label="id: " v-if="data.id">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.id" disabled></Input>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="阶段id: " v-if="data.round_id">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.round_id" disabled></Input>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="主队: ">
                <Row>
                    <Col span="20" >
                        <teamSearch v-model="data.home" :sport_id="sport_id" :classname="locks[String(type)+'-1-'+'home_team_id'] === 1? 'locked-input': '' "></teamSearch>
                    </Col>
                </Row>
            </FormItem>
            
            <FormItem label="客队: ">
                <Row>
                    <Col span="20" >
                        <teamSearch v-model="data.away" :sport_id="sport_id" :classname="locks[String(type)+'-1-'+'away_team_id'] === 1? 'locked-input': '' "></teamSearch>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="主队比分: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.home_score"  ></Input>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="客队比分: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.away_score"  ></Input>
                    </Col>
                </Row>
            </FormItem>
            <!-- winner_team_id  -->
            <FormItem label="胜利队伍: ">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.winner_team_id">
                            <Option :value="0" key="0">无</Option>
                            <Option :value="data.home.id" key="data.home.id">{{data.home.name}}</Option>
                            <Option :value="data.away.id" key="data.away.id">{{data.away.name}}</Option>
                        </Select>
                    </Col>
                </Row>
            </FormItem>
            <!-- type_id 赛制说明（其它为未知） 
            足球:
                赛制说明（其它为未知）：
                    1-单场决胜制
                    2-单场决胜制，如果平局将重赛
                    3-单场决胜制，（存在重赛情况），人工判定胜者
                    4-单场决胜制，人工判定胜者
                    5-两回合制，包含客场进球规则
                    6-两回合制，人工判定胜者
                    7-人工判定胜者
            篮球:
                赛制说明（其它为未知）：
                    1-单场决胜制
                    8-3局2胜制
                    9-5局3胜制
                    10-7局4胜制
            网球:
                赛制说明（其它为未知）：
                    1-单场决胜制
                    2-单场决胜制，如果平局将重赛
                    3-单场决胜制，（存在重赛情况），人工判定胜者
                    4-单场决胜制，人工判定胜者
                    7-人工判定胜者 
            -->
            <FormItem label="赛制类型: ">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.type_id">
                            <Option v-for="item in ( $conf['ballsports']['bracket']['type_id'][sport_id] || $conf['ballsports']['bracket']['type_id'][0] )" :value="item.id" :key="item.id" >
                                {{item.name}}
                            </Option>
                        </Select>
                    </Col>
                </Row>
            </FormItem>

            <!-- state_id 状态说明 
            足球: 
                状态说明（其它为未知）：
                    1-未开赛
                    2-等待对手
                    6-进行中
                    7-主场胜
                    8-客场胜
                    9-取消
            篮球:
                状态说明（其它为未知）：
                    1-未开赛
                    2-等待对手
                    6-进行中
                    7-主场胜
                    8-客场胜
                    9-取消
            网球: 
                状态说明（其它为未知）：
                    1-未开赛
                    2-等待对手
                    3-种子队/种子选手
                    4-部分(种子队/种子选手)
                    5-种子队/种子选手 赛程
                    6-进行中
                    7-主场胜
                    8-客场胜
                    9-取消
            -->
            <FormItem label="状态说明: ">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.state_id">
                            <Option v-for="item in ( $conf['ballsports']['bracket']['state_id'][sport_id] || $conf['ballsports']['bracket']['state_id'][0] )" :value="item.id" :key="item.id" >
                                {{item.name}}
                            </Option>
                        </Select>
                    </Col>
                </Row>
            </FormItem>
            <!-- match_ids -->
            <FormItem label="比赛列表: ">
                <Row>
                    <Col span="20" >
                        <Row v-for="(item, _index) in data.matches" :value="item.id" :key="item.id">
                            <!-- {{ item }} -->
                            <Col span="4">
                                <span class="text-link">{{ item.id }} </span>
                            </Col>
                            <Col span="18">
                                <span class="text-main">{{ item.home.name  }} VS {{ item.away.name }} </span>
                            </Col>
                            <Col span="2">
                                <Icon @click.native="data.matches.splice(_index, 1)" type="ios-close-circle-outline" />
                            </Col>
                        </Row>
                        <matchSearch :sport_id="sport_id" v-model="new_match" @onChooseMatch="addNewMatch" ></matchSearch>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="序号(No.): ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.number" :disabled="! data.id && data.number > 0 " :class="locks[String(type)+'-1-'+'number'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="下一级: ">
                <Row>
                    <Col span="20" >
                        <Row v-for="(item, _index) in data.parent_ids"  :key="_index">
                            <Col span="16">
                                <i-input v-model="data.parent_ids[_index]" placeholder=""></i-input>
                            </Col>
                            <Col span="2" > </Col>
                            <Col span="4">
                                <Icon @click.native="data.parent_ids.splice(_index, 1)" type="ios-close-circle-outline" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col span="20" >
                        <Button shape="circle" icon="md-add" @click="data.parent_ids.push(0)" ></Button>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="上一级: ">
                <Row>
                    <Col span="20" >
                        <Row v-for="(item, _index) in data.children_ids"  :key="_index">
                            <!-- {{ item }} -->
                            <Col span="16">
                                <Input v-model="data.children_ids[_index]" placeholder=""></Input>
                            </Col>
                            <Col span="2" > </Col>
                            <Col span="4">
                                <Icon @click.native="data.children_ids.splice(_index, 1)" type="ios-close-circle-outline" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col span="20" >
                        <Button shape="circle" icon="md-add" @click="data.children_ids.push(0)" ></Button>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="状态: ">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.deleted" :class="locks[String(type) + '-1-' + 'deleted'] === 1? 'locked-select': '' ">
                            <Option :value="1" key="1">隐藏</Option>
                            <Option :value="0" key="0">展示</Option>
                        </Select>
                    </Col>
                </Row>
            </FormItem>
        </Form>
        <Row  align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

// import uniqueTournamentSearch from '../unique_tournament/search.vue';
import { getBracketMatchup, updateBracketMatchup } from '@/api/ballsports/base/season';
import teamSearch from '../team/search.vue';
import matchSearch from '../match/search.vue';

export default {
    name: "matchup-edit",
    props: {
        sport_id: Number,   // 运动id
        matchup_id: Number,   // 轮次id
        matchup_data: Object, // 预定义的内容
    },
    components: {
        teamSearch,
        matchSearch,
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 3,
            type: 1, 
            spinShow: false,
            data: {
                id: 0,
                sport_id: self.sport_id,
                home: {id: 0},
                away: {id: 0},
                deleted: 0,
                children_ids: [],
                parent_ids: [],
            },
            new_match: {
                'id': 0,
                home: {},
                away: {},
            },
            locks: {},
        }
    },
    methods: {
        getData () {
            let self = this;
            if (self.matchup_id && self.matchup_id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    sport_id: this.sport_id,
                    "id" : this.matchup_id,
                }
                getBracketMatchup( params ).then(response => {
                    // console.log(response)
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                        }else{
                            this.data = {}
                        }
                        self.spinShow = false
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                // alert(1)
                if (self.matchup_data){
                    // 预设的内容
                    if (! self.matchup_data.children_ids){
                        self.matchup_data.children_ids = []
                    }
                    if (! self.matchup_data.parent_ids){
                        self.matchup_data.parent_ids = []
                    }
                    self.data = self.matchup_data;
                }
            }
            
        },
        // end 
        emit(params){
            this.$emit('closePage', params)
        },

        // 新增比赛
        addNewMatch(match){
            let self= this;
            // console.log(match);
            if (match.id > 0){
                self.data.matches.push(match);
            }
        },
        // end

        save() {
            let self = this;
            // console.log(self.data)
            // todo round_id
            var match_ids = []
            if (self.data.matches && self.data.matches.length > 0){
                self.data.matches.forEach(element => {
                    if (match_ids.indexOf(element.id) === -1){
                        match_ids.push(element.id);
                    }
                });
            }
            if ( !self.data.number ){
                self.$Message.error("序号请设置为>0的数字");
            }

            let parent_ids = [];
            self.data.parent_ids.forEach(element => {
                let _id = parseInt(element);
                if (_id > 0 && parent_ids.indexOf(_id) === -1){
                    parent_ids.push(_id)
                }
            });
            let children_ids = [];
            self.data.children_ids.forEach(element => {
                let _id = parseInt(element);
                if (_id > 0 && children_ids.indexOf(_id) === -1){
                    children_ids.push(_id)
                }
            });
            let params = {
                id: self.data.id,
                sport_id: self.sport_id,
                round_id: self.data.round_id,
                type_id: self.data.type_id,
                state_id: self.data.state_id,
                home_team_id : self.data.home.id || 0,
                away_team_id : self.data.away.id || 0,
                winner_team_id : self.data.winner_team_id,
                home_score : self.data.home_score,
                away_score : self.data.away_score,
                number: self.data.number,
                match_ids: match_ids,
                parent_ids: parent_ids,
                children_ids: children_ids,
                deleted: self.data.deleted,
            };
            // console.log(params)
            updateBracketMatchup(params).then(response => {
                if (response.data.code == 0) {
                    self.emit(params);
                    self.$Message.success("保存成功")
                } else {
                    self.$Message.error(response.data.msg)
                }
            })
        },
    },
    mounted () {
        let self = this;
        // self.getData();
    },
    watch: {
        'matchup_id': {
            handler(value){
                // console.log("Detail Get Data ID: ", value);
                let self = this;
                self.getData();
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
        'matchup_data': {
            handler(value){
                // console.log("Detail Get Data data: ", value);
                let self = this;
                self.getData();
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
    },
    computed: {
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>