<template>
    <div >
        <Row >
            <AutoComplete
                v-model="search_key"
                placeholder="搜索"
                @on-change = "doSearch"
                @on-blur  = 'blur'
                :class="classname"
            >
                <Option v-for="option in datas" :value="search_key" :tag="option.id" :key="option.id" @click.native="onClicked(option)">
                    <span class="text-link">{{ option.id }}:</span> 
                    <span class="text-main"> {{ transferMatchTime( option.match_time) }}</span>
                    <span class="text-assist"> [{{ option.unique_tournament ? option.unique_tournament.name : '' }}] </span>
                    <span class="text-main">{{ option.home.name }} vs {{ option.away.name }}</span>
                </Option>
            </AutoComplete>
        </Row>
        <Row v-if="value.id && value.id>0">
            <Col span="6" >
                <span class="text-link">{{ value.id }}</span>
            </Col>
            <Col span="6" >
                <span class="text-main">{{ transferMatchTime( value.match_time) }}</span>
            </Col>
            <!-- <Col span="5" >
                <span class="text-assist">{{ value.unique_tournament ? value.unique_tournament.name : '' }} </span>
            </Col> -->
            <Col span="12" >
                <span class="text-main">{{ value.home.name }} vs {{ value.away.name }}</span>
            </Col>
        </Row>
    </div>
    
</template>

<script>

import { searchMatch } from '@/api/ballsports/base/match';
import moment from 'moment';

export default {
    name: "match-search",
    props: {
        sport_id  : Number, 
        value   : Object,    // {id, name, logo}
        classname : String,
        date: '',
    },
    components: {
    },
    data () {
        let self = (window.x = this)
        return {
            search_key: '',
            data: {
                'id': 0,
                'home': {},
                'away': {},
            },
            datas: {},
            loading: false,
        }
    },
    methods: {
        doSearch (value) {
            let self = this;
            self.$unit.debounce( () => {
                // console.log("PLAYER SEARCH:", value,  self.data);
                if ( value ){
                    self.loading = true;
                    if(this.date){
                        var new_date = moment( this.date).unix();
                    }else{
                        var new_date = 0;
                    }
                    let params = {
                        sport_id: self.sport_id,
                        search_key: value,
                        date: new_date,
                    }
                    searchMatch(params).then(response => {
                        if (response.data.code == 0) {
                            self.datas = response.data.data.list;
                        } else {
                            this.$Message.error(response.data.msg);
                        }
                    })
                    self.loading = false;
                }else if (value ){
                    self.datas = {}
                    self.datas [self.data.id]= self.data
                }
            }, 300, 'search_match')
        },
        // end
        onClicked(data){
            // console.log("onClicked(option)", data)
            let self = this;
            self.data = data;
            self._emit();
            this.$emit('onChooseMatch', this.data);
            self.search_key = ''; // self.data.name;
        },
        // end
        _emit(){
            this.$emit('input', this.data);
        },
        // end
        blur(){
            // 失去焦点时触发
            let self = this;
            if (self.search_key){
                self.search_key = self.data.name;
            }else{
                self.data = {
                    'id': 0,
                    'home': {},
                    'away': {},
                };
                self.datas = [];
            }
            self._emit();
        },
        // end
        transferMatchTime(tsp){
            if (tsp){
                return moment.unix(tsp).format('YYYY-MM-DD HH:mm');
            }else{
                return '';
            }
        },
        // end 
    },
    mounted () {
    },
    watch: {
        'value': {
            handler: function(value){
                this.data = value;
                // this.search_key = value.name;
            },
            deep: true,
        },
        // end 
    },
    computed: {
    }
}
</script>
<style scoped>
    /* @import "search.css" */
</style>