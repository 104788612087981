import http from '@/utils/http';

// 获取赛季列表
export const getSeason = (params) => {
    return http.request({
        url: '/v1/ballsports/base/season/list',
        method: 'get',
        params: params
    })
}

// 新增赛季的关联
export const addSeasonRelation = (params) => {
    return http.request({
        url: '/v1/ballsports/base/season/relation/add',
        method: 'post',
        data: params
    })
}

// 删除赛季的关联
export const delSeasonRelation = (params) => {
    return http.request({
        url: '/v1/ballsports/base/season/relation/del',
        method: 'post',
        data: params
    })
}

// 获取赛季的所有源
export const getSeasonSources = (params) => {
    return http.request({
        url: '/v1/ballsports/base/season/source/list',
        method: 'get',
        params: params
    })
}

// 搜索
export const searchSeason = (params) => {
    return http.request({
        url: '/v1/ballsports/base/season/search',
        method: 'post',
        data: params
    })
}

// 更新比赛
export const updateSeason = (params) => {
    return http.request({
        url: "/v1/ballsports/base/season/update",
        method: 'post',
        data: params,
    })
}

// 获取对阵
export const getSeasonBrackets = (params) => {
    return http.request({
        url: '/v1/ballsports/base/season/bracket',
        method: 'get',
        params: params
    })
}
// 获取对阵round列表
export const getBracketRound = (params) => {
    return http.request({
        url: '/v1/ballsports/base/season/bracket/round/list',
        method: 'get',
        params: params
    })
}
// 更新round
export const updateBracketRound = (params) => {
    return http.request({
        url: "/v1/ballsports/base/season/bracket/round/update",
        method: 'post',
        data: params,
    })
}
// 获取对阵matchups
export const getBracketMatchup = (params) => {
    return http.request({
        url: '/v1/ballsports/base/season/bracket/matchup/list',
        method: 'get',
        params: params
    })
}
// 更新matchups
export const updateBracketMatchup = (params) => {
    return http.request({
        url: "/v1/ballsports/base/season/bracket/matchup/update",
        method: 'post',
        data: params,
    })
}
// 删除matchups
export const deleteBracketMatchup = (params) => {
    return http.request({
        url: "/v1/ballsports/base/season/bracket/matchup/delete",
        method: 'post',
        data: params,
    })
}

// 更新对阵图锁的状态
export const updateBracketLock = (params) => {
    return http.request({
        url: "/v1/ballsports/base/season/bracket/lock/update",
        method: 'post',
        data: params,
    })
}

// 获取对阵matchups
export const getBracket = (params) => {
    return http.request({
        url: '/v1/ballsports/base/season/bracket/list',
        method: 'get',
        params: params
    })
}
// 更新matchups
export const updateBracket = (params) => {
    return http.request({
        url: "/v1/ballsports/base/season/bracket/update",
        method: 'post',
        data: params,
    })
}

export const getGroup = (params) => {
    return http.request({
        url: '/v1/ballsports/base/season/group/list',
        method: 'get',
        params: params
    })
}
export const updateGroup = (params) => {
    return http.request({
        url: "/v1/ballsports/base/season/group/update",
        method: 'post',
        data: params,
    })
}

// 获取tables
export const getTable = (params) => {
    return http.request({
        url: '/v1/ballsports/base/season/table/list',
        method: 'get',
        params: params
    })
}
// 更新table
export const updateTable = (params) => {
    return http.request({
        url: "/v1/ballsports/base/season/table/update",
        method: 'post',
        data: params,
    })
}
// 获取tableRow
export const getTableRow = (params) => {
    return http.request({
        url: '/v1/ballsports/base/season/table/rows/list',
        method: 'get',
        params: params
    })
}
// 更新tableRow
export const updateTableRow = (params) => {
    return http.request({
        url: "/v1/ballsports/base/season/table/rows/update",
        method: 'post',
        data: params,
    })
}

// 新增对阵图线
export const addMatchUpLine = (params) => {
    return http.request({
        url: "/v1/ballsports/base/season/bracket/matchup/line/add",
        method: 'post',
        data: params,
    })
}
// 删除对阵图线
export const deleteMatchUpLine = (params) => {
    return http.request({
        url: "/v1/ballsports/base/season/bracket/matchup/line/delete",
        method: 'post',
        data: params,
    })
}