<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Form label-position="left" :label-width="80" >
            <FormItem label="id: " v-if="data.id">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.id" disabled></Input>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="英文名称: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.name" :class="locks[String(type)+'-1-'+'name'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="中文名称: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.name_zh" :class="locks[String(type)+'-1-'+'name_zh'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="赛事英文: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.abbr" :class="locks[String(type)+'-1-'+'abbr'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="赛事中文: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.abbr_zh" :class="locks[String(type)+'-1-'+'abbr_zh'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="开始时间: " >
                <Row>
                    <Col span="20" >
                        <DatePicker 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm" 
                            :class="locks[String(type) + '-1-' + 'start_time'] === 1? 'locked-input': '' "
                            style="width: 100%"
                            v-model="start_time"
                        >
                        </DatePicker>
                    </Col>
                    
                </Row>
            </FormItem>
            <FormItem label="结束时间: " >
                <Row>
                    <Col span="20" >
                        <DatePicker 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm" 
                            :class="locks[String(type) + '-1-' + 'end_time'] === 1? 'locked-input': '' "
                            style="width: 100%"
                            v-model="end_time"
                        >
                        </DatePicker>
                    </Col>
                    
                </Row>
            </FormItem>

            <FormItem label="类型: ">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.type_id" >
                            <Option :value="0" key="0">未知</Option>
                            <Option :value="1" key="1">Single-elimination, fixed 单败淘汰制，固定</Option>
                            <Option :value="2" key="2">Single-elimination, each round drawn 单败淘汰制（每轮平局）</Option>
                            <Option :value="3" key="3">Single-elimination, dynamic 单败淘汰制，不固定</Option>
                        </Select>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="状况: ">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.state_id" >
                            <Option :value="0" key="0">未知</Option>
                            <Option :value="-5" key="-5">validnotconfirmed（未确认有效）</Option>
                            <Option :value="-4" key="-4">brokentest：（测试中断）</Option>
                            <Option :value="-3" key="-3">validtest：(测试有效)</Option>
                            <Option :value="-2" key="-2">broken：腰斩</Option>
                            <Option :value="2" key="2">valid：有效的</Option>
                            <Option :value="3" key="3">validconfirmed：（确认有效）</Option>
                        </Select>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="状态: ">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.deleted" :class="locks[String(type) + '-1-' + 'deleted'] === 1? 'locked-select': '' ">
                            <Option :value="1" key="1">隐藏</Option>
                            <Option :value="0" key="0">展示</Option>
                        </Select>
                    </Col>
                </Row>
            </FormItem>
        </Form>
        <Row  align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import { getBracket, updateBracket } from '@/api/ballsports/base/season';
import moment from 'moment';

export default {
    name: "bracket-edit",
    props: {
        sport_id: Number,   // 运动id
        season_id: Number,   // 赛季id
        bracket_id: Number,   // 轮次id
    },
    components: {
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 3,
            type: 1, 
            spinShow: false,
            data: {
                id: 0,
                start_time: parseInt( moment() / 1000 ),
                end_time: parseInt( moment() / 1000),
                deleted: 0,
                sport_id: self.sport_id,
            },
            locks: {},
        }
    },
    methods: {
        getData () {
            let self = this;
            if (self.bracket_id && self.bracket_id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    sport_id: this.sport_id,
                    "id" : this.bracket_id,
                }
                getBracket( params ).then(response => {
                    // console.log(response)
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                        }else{
                            this.data = {}
                        }
                        self.spinShow = false
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                // alert(1)
                self.data =  {
                    id: 0,
                    start_time: parseInt( moment() / 1000 ),
                    end_time: parseInt( moment() / 1000),
                    deleted: 0,
                    sport_id: self.sport_id,
                }
            }
            
        },
        // end 
        emit(params){
            this.$emit('closePage', params)
        },

        save() {
            let self = this;
            // console.log(self.data)
            let params = {
                id: self.data.id,
                sport_id: self.sport_id,
                season_id: self.season_id,
                type_id: self.data.type_id,
                state_id: self.data.state_id,

                name: self.data.name,
                abbr: self.data.abbr,
                name_zh: self.data.name_zh,
                abbr_zh: self.data.abbr_zh,
                start_time: self.data.start_time,
                end_time : self.data.end_time,
                deleted: self.data.deleted,
            };
            // console.log(params)
            updateBracket(params).then(response => {
                if (response.data.code == 0) {
                    self.emit(params);
                    self.$Message.success("保存成功")
                } else {
                    self.$Message.error(response.data.msg)
                }
            })
        },
    },
    mounted () {
        let self = this;
        // self.getData();
    },
    watch: {
        'bracket_id': {
            handler(value){
                // console.log("Detail Get Data", value);
                let self = this;
                self.getData();
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
    },
    computed: {
        start_time: {
            get: function () {
                return moment.unix(this.data.start_time).toDate();
            },
            set: function (newValue) {
                this.data.start_time = moment(newValue).unix();
            }
        },
        end_time: {
            get: function () {
                return moment.unix(this.data.end_time).toDate();
            },
            set: function (newValue) {
                this.data.end_time = moment(newValue).unix();
            }
        },
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>