import http from '@/utils/http';

// 获取队伍列表
export const getTeam = (params) => {
    return http.request({
        url: '/v1/ballsports/base/team/list',
        method: 'get',
        params: params
    })
}

// 新增队伍的关联
export const addTeamRelation = (params) => {
    return http.request({
        url: '/v1/ballsports/base/team/relation/add',
        method: 'post',
        data: params
    })
}

// 删除队伍的关联
export const delTeamRelation = (params) => {
    return http.request({
        url: '/v1/ballsports/base/team/relation/del',
        method: 'post',
        data: params
    })
}

// 获取队伍的所有源
export const getTeamSources = (params) => {
    return http.request({
        url: '/v1/ballsports/base/team/source/list',
        method: 'get',
        params: params
    })
}

// 获取队伍分类列表
export const getTeamCategory = (params) => {
    return http.request({
        url: '/v1/ballsports/base/team/category/list',
        method: 'get',
        params: params
    })
}

// 搜索
export const searchTeam = (params) => {
    return http.request({
        url: '/v1/ballsports/base/team/search',
        method: 'post',
        data: params
    })
}

// 更新比赛
export const updateTeam = (params) => {
    return http.request({
        url: "/v1/ballsports/base/team/update",
        method: 'post',
        data: params,
    })
}

export const updateTeamTran = (params) => {
    return http.request({
        url: "/v1/ballsports/base/team/tran/update",
        method: 'post',
        data: params,
    })
}