<template>
    <div style="width:100%">
        <Drawer
            v-model="edit_round_drawer"
            width="600"
            :mask-closable="true"
        >
            <roundEdit :sport_id="sport_id" :round_id="edit_round_id" :bracket_id="edit_bracket_id" :group_id="edit_group_id" @closePage='closeRoundEditPage'></roundEdit>
        </Drawer>
        <Drawer
            v-model="edit_matchup_drawer"
            width="600"
            :mask-closable="true"
        >
            <matchupEdit :sport_id="sport_id" :matchup_id="edit_matchup_id" :matchup_data="edit_matchup_data" @closePage='closeMatchupEditPage'></matchupEdit>
        </Drawer>

        <Drawer
            v-model="edit_bracket_drawer"
            width="600"
            :mask-closable="true"
        >
            <bracketEdit :sport_id="sport_id" :season_id="season_id" :bracket_id="edit_bracket_id" @closePage='closeBracketEditPage' ></bracketEdit>
        </Drawer>

        <Drawer
            v-model="edit_group_drawer"
            width="600"
            :mask-closable="true"
        >
            <groupEdit :sport_id="sport_id" :bracket_id="edit_bracket_id" :group_id="edit_group_id" @closePage='closeGroupEditPage' ></groupEdit>
        </Drawer>

        <!-- <Row>{{bracket_id}}- {{typeof bracket_id}} - {{group_id}} - {{typeof group_id}} </Row> -->
        <!-- <Button type="success" style="text-align: right;" @click="addNode()">新增节点</Button> -->
        <!-- <Button type="info" style="text-align: right;" @click="saveToDb()">结束编辑并保存</Button> -->
        <Tabs name="tab1" v-model="bracket_id" capture-focus >
            <!-- closable @on-tab-remove="handleTabStageRemove" :before-remove="beforeTabStageRemove" -->
            <!-- item.name -->
            <TabPane v-for="item in brackets" :key="parseInt(item.id)" :label="getBracketTabLabel(item)" :name="String(item.id)" tab="tab1">
                <Tabs name="tab2" v-if="item.groups" v-model="group_id" >
                    <TabPane v-for="group in item.groups" :name="String(group.id)" :key="parseInt( group.id )" :label="getGroupTabLabel(group)" tab="tab2">
                        <div ref="Maps" :id="createNodeId(item.id, group.id)" style="background: #FFF; height:650px"></div>
                    </TabPane>
                    <!-- <Button @click="handleGroupAdd" slot="extra">增加子阶段</Button> -->
                    <Row slot="extra">
                        <Col>
                            <Button @click="edit_bracket_id=parseInt(bracket_id); edit_group_id=parseInt( group_id); edit_group_drawer=true">编辑</Button>
                            <Button @click="edit_bracket_id=parseInt(bracket_id); edit_group_id=0; edit_group_drawer=true">新增</Button>
                        </Col>
                    </Row>
                </Tabs>
                <Tabs name="tab2" v-else>
                    <Row slot="extra">
                        <Col>
                            <Button @click="edit_bracket_id=parseInt(bracket_id); edit_group_id=0; edit_group_drawer=true">新增</Button>
                        </Col>
                    </Row>
                </Tabs>
            </TabPane>
            <!-- <Button slot="extra">增加主阶段</Button> -->
            <Row slot="extra">
                <Col>
                    <Button @click="edit_bracket_id=parseInt(bracket_id) ;edit_bracket_drawer=true">编辑</Button>
                    <Button @click="edit_bracket_id=0 ;edit_bracket_drawer=true">新增</Button>
                </Col>
                <Col>
                    <Tooltip max-width='100' >
                        <span class="text-main">数据锁: </span><i-switch v-model="locked" @on-change="lockedChanged" />
                        <div slot="content">
                            <p>锁定后只能人工编辑,不会自动覆盖;</p>
                            <p><i>如果没有锁定,可能会被机器覆盖掉</i></p>
                        </div>
                    </Tooltip>
                </Col>
            </Row>
        </Tabs>
        <!-- <div :id="createNodeId()" style="background: #EEE; height:700px"></div> -->
    </div>
</template>

<script>

import G6 from '@antv/g6';
import { getSeasonBrackets, addMatchUpLine, deleteMatchUpLine } from '@/api/ballsports/base/season';
import { deleteBracketMatchup, updateBracketLock } from '@/api/ballsports/base/season';
import {getImgUrl} from '@/utils/util';
import roundEdit from './round_edit.vue';
import matchupEdit from './matchup_edit.vue';
import bracketEdit from './bracket_edit.vue';
import groupEdit from './group_edit.vue';
// 新增节点: graph.addItem( 'node', json数据);    
// 更新节点: graph.updateItem( node_id, json数据);
// 销毁 图:  graph.destroy();

export default {
    name: 'match_map',
    // props: ['value', "stage_id", "group_id", "conf", "sub_type", "teams", 'game_id', 'tournament_id',],
    props: ['value', 'sport_id', 'season_id',],
    data() {
        return {
            bracket_id: null,
            group_id  : null,

            edit_bracket_id: 0,
            edit_group_id: 0,

            round_id  : 0,
            brackets  : [],   // 轮次
            graphs    : {},   // div id和图的对应 
            team_width : 250,
            team_height : 50,
            banner_height : 2,
            match_height : 20,
            focused_item_id: '',
            demo_data: {
                // 点集
                nodes: [
                    {
                        "id": 'node1',
                        x: 0, // Number，可选，节点位置的 x 值
                        y: 0, // Number，可选，节点位置的 y 值
                    },
                    {
                        id: 'node2', // String，该节点存在则必须，节点的唯一标识
                        x: 300, // Number，可选，节点位置的 x 值
                        y: 200, // Number，可选，节点位置的 y 值
                    },
                ],
                // 边集
                edges: [
                    // {
                    //     source: 'node1', // String，必须，起始点 id
                    //     target: 'node2',  // String，必须，目标点 id
                    // }
                ]
            },
            edit_round_drawer: false,
            edit_round_id   : 0,
            edit_matchup_drawer: false,
            edit_matchup_id   : 0,
            edit_matchup_data : {
                id: 0,
                sport_id: self.sport_id,
                home: {id: 0},
                away: {id: 0},
                deleted: 0,
            },
            edit_bracket_drawer: false,
            edit_group_drawer: false,
            // end !
            graph: null,
            edit_match: {  // 编辑的比赛
            },
            edit_modal: false,  // 编辑比赛的模态框
            update_to_nodes: true,  // 更新到所有的节点
            max_win_sequences: 0,
            // 对阵图数据 是否加锁
            locked: false,
        }
    },
    created() {
    },
    watch:{
        // value (new_value) {
        //     let self = this;
        //     // console.log(111);
        // },
        bracket_id(value){
            let self = this;
            for (var bracket_id in self.brackets){
                if ( bracket_id == value ){
                    let groups = self.brackets[bracket_id].groups || [];
                    let group_ids = [];
                    groups.forEach(element => {
                        group_ids.push(String(element.id));
                    });
                    // console.log(group_ids)
                    if (group_ids.length>0 && group_ids.indexOf(self.group_id) === -1){
                        self.group_id = group_ids[0];
                    }
                }
            }
        },
        // end
    },
    mounted () {
        let self = this;
        self.registMatchLine();
        self.registMatchNode();
        self.getData();
    },
    components: {
        roundEdit,
        matchupEdit,
        bracketEdit,
        groupEdit,
    },
    methods: {
        // 获取label名称
        getBracketTabLabel(item){
            if (item.deleted === 1 ){
                return (h) => h('div', [
                    h('span', {class: "text-assist"}, item.name),
                ])
            }else{
                return (h) => h('div', [
                    h('span', item.name),
                ])
            }
        },
        getGroupTabLabel(item){
            if (item.deleted === 1 ){
                return (h) => h('div', [
                    h('span', {class: "text-assist"}, item.id),
                ])
            }else{
                return (h) => h('div', [
                    h('span', item.id),
                ])
            }
        },
        // end
        // 截取字符串
        strHead(a, len=8){
            if (a.length > len){
                return a.slice(0, len) + '...'
            }
            return a;
        },
        // end 
        createNodeId(bracket_id, group_id){
            let self = this;
            let _id  = "mountNode_" + String( bracket_id ) + "_" + String( group_id );
            return _id;
        },
        // end
        getCurrentGraph(){
            let self = this;
            let containter_id = self.createNodeId(self.bracket_id, self.group_id);
            return self.graphs[containter_id];
        },
        // end
        registMatchLine(){
            G6.registerEdge(
            'line-arrow',
            {
                getPath(points) {
                const startPoint = points[0];
                const endPoint = points[1];
                return [
                    ['M', startPoint.x, startPoint.y],
                    // ['L', endPoint.x / 2 + 1 * startPoint.x, startPoint.y],
                    // ['L', endPoint.x / 2 + 1 * startPoint.x, endPoint.y],
                    ['L', startPoint.x + 0.5*( endPoint.x - startPoint.x), startPoint.y],
                    ['L', startPoint.x + 0.5*( endPoint.x - startPoint.x), endPoint.y],
                    ['L', endPoint.x, endPoint.y],
                ];
                },
                getShapeStyle(cfg) {
                const startPoint = cfg.startPoint;
                const endPoint = cfg.endPoint;
                const controlPoints = this.getControlPoints(cfg);
                let points = [startPoint]; // the start point
                // the control points
                if (controlPoints) {
                    points = points.concat(controlPoints);
                }
                // the end point
                points.push(endPoint);
                const path = this.getPath(points);
                const style = Object.assign(
                    {},
                    G6.Global.defaultEdge.style,
                    {
                    stroke: '#BBB',
                    lineWidth: 1,
                    path,
                    },
                    cfg.style,
                );
                return style;
                },
            },
            'line',
            );
        },

        registMatchNode(){
            let self = this;
            G6.registerNode('match-node',
            {
            drawShape: function drawShape(cfg, group) {
                //最外面的那层
                // const color = cfg.error ? '#F4664A' : '#30BF78';
                // const row_id = cfg.id || '';
                const r = 8;
                const node_type = cfg.node_type;  // 是否轮空
                const team_width = self.team_width;
                const team_height = self.team_height;
                const banner_height = 2;
                const match_x = cfg.x;
                const match_y = cfg.y;
                const match_height = self.match_height;
                const home_x = match_x;
                const home_y = match_y + match_height ;
                const away_x = home_x;
                const away_y = home_y + team_height + 1;
                
                cfg.anchorPoints = [
                    [0, 0.5],
                    [1, 0.5],
                ];
                if (node_type === 1){
                    let deleted = cfg.deleted; 
                    let id_color = deleted ? '#9ea7b4': '#3399ff';
                    let _text = cfg.name || cfg.long_name || cfg.abbr;
                    let round_id = cfg.id.slice(2);
                    if (round_id < 0){
                        round_id *= -1
                    }
                    var shape = group.addShape('rect', {
                        draggable: false,
                        name: 'match-box',
                        attrs: {
                            x: match_x,
                            y: match_y,
                            width: team_width,
                            height:  team_height ,
                            radius: 12,
                            fill: "#FFF",
                        },
                    });
                    // rounds
                    group.addShape('rect', {
                        draggable: false,
                        name: 'match-box',
                        attrs: {
                            x: match_x,
                            y: match_y,
                            width: team_width,
                            height: team_height ,
                            radius: 12,
                            fill: "#EEE",
                        },
                    });
                    group.addShape('text', {
                        draggable: false,
                        attrs: {
                            textBaseline: 'center',
                            x: match_x + 5,
                            y: match_y + 0,
                            lineHeight: 20,
                            fontSize: 18,
                            text: "ID: " + round_id,  // 轮次名称
                            fill: id_color,
                        },
                    });
                    group.addShape('text', {
                        draggable: false,
                        attrs: {
                            textBaseline: 'center',
                            x: match_x + 5,
                            y: match_y + team_height - 20/2,
                            lineHeight: 20,
                            fontSize: 20,
                            text: _text,  // 轮次名称
                            fill: '#333',
                        },
                    });
                }else{
                    var home_team = cfg.home || {};
                    var away_team = cfg.away || {};
                    const match_id = cfg.id || '';
                    let match_number = cfg.match_number;
                    let deleted = cfg.deleted; 
                    // const match_time = cfg.match_time? moment.unix(cfg.match_time).format('YYYY-MM-DD HH:mm'):'';
                    var shape = group.addShape('rect', {
                        draggable: false,
                        name: 'match-box',
                        attrs: {
                            x: match_x,
                            y: match_y,
                            width: team_width,
                            height: match_height + team_height * 2,
                            radius: 0,
                            // fill: "#F4664A",
                        },
                    });
                    //文字 比赛id
                    let id_color = deleted ? '#9ea7b4': '#3399ff';
                    group.addShape('text', {
                        draggable: false,
                        attrs: {
                        // textBaseline: 'center',
                        x: match_x + 3,
                        y: match_y + match_height,
                        lineHeight: 20,
                        fontSize: 16,
                        text: "ID: " + match_id,  // 坐标
                        fill: id_color,
                        },
                    });
                    group.addShape('text', {
                        draggable: false,
                        attrs: {
                        // textBaseline: 'center',
                        x: match_x + team_width * 0.8,
                        y: match_y + match_height,
                        lineHeight: 20,
                        fontSize: 16,
                        text: "No." + match_number,  // 比赛id
                        fill: '#9ea7b4',
                        },
                    });
                    // group.addShape('text', {
                    //     draggable: false,
                    //     attrs: {
                    //     // textBaseline: 'center',
                    //     x: home_x  ,
                    //     y: home_y - 20 ,
                    //     lineHeight: 20,
                    //     fontSize: 14,
                    //     text: match_time,  // 比赛id
                    //     fill: '#000',
                    //     },
                    // });
                    // if (true){
                    //     group.addShape('text', {
                    //     draggable: false,
                    //     attrs: {
                    //         // textBaseline: 'center',
                    //         x: home_x ,
                    //         y: home_y - 40 ,
                    //         lineHeight: 20,
                    //         fontSize: 14,
                    //         text: '轮次名称',  // 比赛id
                    //         fill: '#000',
                    //     },
                    //     });
                    // }
                    // 主队边框
                    group.addShape('rect', {
                        draggable: false,
                        name: 'home-box',
                        attrs: {
                        x: home_x,
                        y: home_y,
                        width: team_width,
                        height: team_height,
                        stroke: '', //边框
                        radius: r,
                        fill: "#F4664A",
                        },
                    });
                    // 主队内容面板
                    group.addShape('rect', {
                        draggable: false,
                        name: 'home-box-inside',
                        attrs: {
                        x: home_x+ 1,
                        y: home_y+ banner_height,
                        width: team_width - 2,
                        height: team_height - banner_height -1,
                        stroke: '', //边框
                        radius: r,
                        fill: "#FFF",
                        },
                    });
                    //客队外框
                    group.addShape('rect', {
                        draggable: false,
                        name: 'away-box',
                        attrs: {
                        x: away_x,
                        y: away_y,
                        width: team_width,
                        height: team_height,
                        fill: '#30BF78', //填充色
                        stroke: '', //边框
                        radius: r,
                        },
                    });
                    group.addShape('rect', {
                        draggable: false,
                        name: 'away-box-inside',
                        attrs: {
                        x: away_x +1,
                        y: away_y +banner_height,
                        width: team_width-2,
                        height: team_height-banner_height-1,
                        fill: '#FFF', //填充色
                        stroke: '', //边框
                        radius: r,
                        },
                    });
                    // 主队logo
                    // let away_logo = getImgUrl(self.sport_id, 'team', away_team.logo) || ''
                    // if (away_logo){
                    //     var away_img = new Image;
                    //     away_img.crossOrigin = 'anonymous'; //可选值：anonymous，*  
                    //     away_img.src = away_logo;
                    // }else{
                    //     var away_img = '';
                    // }
                    group.addShape('image', {
                        draggable: false,
                        name: 'home-icon',
                        attrs: {
                            x: home_x ,
                            y: home_y + banner_height,
                            height: team_height - banner_height ,
                            width:  team_height - banner_height ,
                            cursor: 'pointer',
                            img: getImgUrl(self.sport_id, 'team', home_team.logo) || '',
                        },
                    });
                    group.addShape('image', {
                        draggable: false,
                        name: 'away-icon',
                        attrs: {
                            x: away_x ,
                            y: away_y + banner_height,
                            height: team_height - banner_height,
                            width:  team_height - banner_height,
                            cursor: 'pointer',
                            img: getImgUrl(self.sport_id, 'team', away_team.logo) || '',
                            // crossOrigin : 'anonymous',
                        },
                    });
                    // 战队名称
                    let home_name = self.strHead(home_team.name || '', 10);
                    let away_name = self.strHead(away_team.name || '', 10);
                    group.addShape('text', {
                        draggable: false,
                        name: 'home-id',
                        attrs: {
                            x: home_x + team_height + banner_height,
                            y: home_y + team_height - 20 ,
                            lineHeight: 20,
                            fontSize: 15,
                            text: home_team.id ,
                            fill: '#3399ff',
                        },
                    });
                    group.addShape('text', {
                        draggable: false,
                        name: 'away-id',
                        attrs: {
                            // textBaseline: 'center',
                            x: away_x + team_height + banner_height ,
                            y: away_y + team_height - 20 ,
                            lineHeight: 20,
                            fontSize: 15,
                            text: away_team.id ,
                            fill: '#3399ff',
                        },
                    });
                    group.addShape('text', {
                        draggable: false,
                        name: 'home-name',
                        attrs: {
                            x: home_x + team_height + banner_height,
                            y: home_y + team_height ,
                            lineHeight: 20,
                            fontSize: 15,
                            text: home_name ,
                            fill: '#666',
                        },
                    });
                    group.addShape('text', {
                        draggable: false,
                        name: 'away-name',
                        attrs: {
                            // textBaseline: 'center',
                            x: away_x + team_height + banner_height ,
                            y: away_y + team_height ,
                            lineHeight: 20,
                            fontSize: 15,
                            text: away_name ,
                            fill: '#666',
                        },
                    });
                    // 比分
                    // let home_score = cfg.home_score;
                    // let away_score = cfg.away_score;
                    let winner_team_id = cfg.winner_team_id;
                    let home_score_color = '#000';
                    let away_score_color = '#000';
                    if (winner_team_id == home_team.id){
                        home_score_color = '#F00';
                    }else if (winner_team_id == away_team.id){
                        away_score_color = '#F00';
                    }
                    group.addShape('text', {
                        draggable: false,
                        name: 'home-score',
                        attrs: {
                            x: home_x + team_width -20 ,
                            y: home_y + team_height - 10,
                            lineHeight: 20,
                            fontSize: 20,
                            text: cfg.home_score || '0',
                            fill: home_score_color,
                        },
                    });
                    group.addShape('text', {
                        draggable: false,
                        name: 'away-score',
                        attrs: {
                            // textBaseline: 'center',
                            x: away_x + team_width -20 ,
                            y: away_y + team_height - 10,
                            lineHeight: 20,
                            fontSize: 20,
                            text: cfg.away_score || '0' ,
                            fill: away_score_color,
                        },
                    });
                    // 删除


                }
                return shape;
            },
            });
        },
        // end
        // 获取数据
        getData(){
            let self = this;
            let params = {
                sport_id: self.sport_id,
                season_id: self.season_id,
                // bracket_id: self.bracket_id,
                // group_id: self.group_id,
                // round_id: self.round_id,
            };
            getSeasonBrackets(params).then(response => {
                if (response.data.code === 0) {
                    self.brackets = response.data.data;
                    self.locked = response.data.locked;
                    self.$nextTick(() => {
                        self.demoGraph();
                        if (self.focused_item_id){
                            // console.log("focused: ", self.focused_item_id);
                            let graph = self.getCurrentGraph();
                            let focus_node = graph.findById(self.focused_item_id);
                            // graph.focusItem(item, animate, animateCfg)
                            if (focus_node){
                                graph.focusItem( focus_node, 
                                    // true, {
                                    //     easing: 'easeCubic',
                                    //     duration: 400,
                                    // } 
                                );
                            }
                        }
                    })
                }else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end
        demoGraph( ){
            let self = this;
            // console.log(self.graphs);
            // console.log(container_id, typeof container_id);
            for (var bracket_id in self.brackets){
                if (! self.bracket_id ){
                    self.bracket_id = bracket_id;
                }
                let groups = self.brackets[bracket_id].groups || [];
                groups.forEach(group => {
                    let contextMenu = new G6.Menu({
                        getContent(evt) {
                            // let header;
                            if (evt.target && evt.target.isCanvas && evt.target.isCanvas()) {
                                // header = 'Canvas ContextMenu';
                                // alert(1);
                                return `
                                    <div style='min-width: 80px; background: #fff;'>
                                        <h4>操作</h4>
                                        <ul style="
                                            list-style-type: none;
                                            margin-top: 10px;
                                        ">
                                            <li title='1' style="margin-top: 10px;" >新增轮次</li>
                                        </ul>
                                    </div>`;
                            } else if (evt.item) {
                                const itemType = evt.item.getType();
                                let clicked_model = evt.item? evt.item._cfg.model : {};
                                // console.log(itemType, evt.item);
                                // header = `${itemType.toUpperCase()} `;
                                if (itemType == 'node' && clicked_model.node_type === 2 ){
                                    // 右键点击比赛节点
                                    return `
                                    <div style='min-width: 80px; background: #fff;'>
                                        <h4>比赛操作</h4>
                                        <ul style="
                                            list-style-type: none;
                                            margin-top: 10px;
                                        ">
                                            <li title='1' style="margin-top: 10px;" >上方新增比赛</li>
                                            <li title='2' style="margin-top: 10px;" >下方新增比赛</li>
                                            <li title='3' style="margin-top: 10px;" >删除比赛</li>
                                        </ul>
                                    </div>`;
                                }else if (itemType == 'node' && clicked_model.node_type === 1 ){
                                    return `
                                    <div style='min-width: 80px; background: #fff;'>
                                        <h4>阶段操作</h4>
                                        <ul style="
                                            list-style-type: none;
                                            margin-top: 10px;
                                        ">
                                            <li title='1' style="margin-top: 10px;" >新增比赛</li>
                                        </ul>
                                    </div>`;
                                }else if (itemType == 'edge'){
                                    // 边
                                    return `
                                    <div style='min-width: 80px; background: #fff;'>
                                        <h4>边操作</h4>
                                        <ul style="
                                            list-style-type: none;
                                            margin-top: 10px;
                                        ">
                                            <li title='1' style="margin-top: 10px;" >删除边</li>
                                        </ul>
                                    </div>`;
                                }
                            }
                        },
                        handleMenuClick: (target, item) => {
                            // console.log(target, item);
                            const title= target.getAttribute('title') || '0';
                            if  (item){
                                // 点击节点
                                const item_type = item.getType();
                                // console.log( title, item_type, item);
                                let clicked_model = item? item._cfg.model : {};
                                // console.log(clicked_model);
                                if (item_type == 'node' && clicked_model.node_type === 2){
                                    // 1 上方新增比赛
                                    // 2 下方新增比赛
                                    // 3 删除节点
                                    self.focused_item_id = clicked_model.id;
                                    if (title === '1'){
                                        // 编辑比赛
                                        // self.edit_match = JSON.parse(JSON.stringify( item._cfg.model ));
                                        // self.edit_modal = true;
                                        self.edit_matchup_data = {
                                            id: 0,
                                            sport_id: self.sport_id,
                                            home: {id: 0},
                                            away: {id: 0},
                                            deleted: 0,
                                            number  : clicked_model.match_number,
                                            round_id: clicked_model.round_id,
                                        }
                                    }
                                    else if (title === '2'){
                                        self.edit_matchup_data = {
                                            id: 0,
                                            sport_id: self.sport_id,
                                            home: {id: 0},
                                            away: {id: 0},
                                            deleted: 0,
                                            number  : clicked_model.match_number + 1,
                                            round_id: clicked_model.round_id,
                                        }
                                    }
                                    else if (title === '3'){
                                        // 删除节点
                                        let matchup_id = clicked_model.id;
                                        self.$Modal.confirm({
                                            title: "确认删除?",
                                            content: "删除立即生效,请确认删除当前比赛 ID:" + matchup_id,
                                            okText: "确定",
                                            cancelText: "取消",
                                            onOk : async () => {
                                                // 删除当前节点
                                                deleteBracketMatchup({ "id": matchup_id }).then(response => {
                                                    if (response.data.code === 0) {
                                                        let graph = self.getCurrentGraph();
                                                        if (graph){
                                                            graph.removeItem(item);
                                                        }
                                                    }else {
                                                        self.$Message.error(response.data.msg);
                                                    }
                                                })
                                            }
                                        })
                                        return
                                    }
                                    self.edit_matchup_id = 0;
                                    self.edit_matchup_drawer = true;
                                }else if (item_type == 'node' && clicked_model.node_type === 1){
                                    // 1 新增比赛
                                    self.focused_item_id = clicked_model.id;
                                    if (title === '1'){
                                        // 新增比赛
                                        let _id = parseInt( clicked_model.id.slice(2) )
                                        if (_id < 0){
                                            _id = -1 * _id
                                        }
                                        self.edit_matchup_data = {
                                            id: 0,
                                            sport_id: self.sport_id,
                                            home: {id: 0},
                                            away: {id: 0},
                                            deleted: 0,
                                            round_id: _id,
                                        }
                                        self.edit_matchup_id = 0;
                                        self.edit_matchup_drawer = true;
                                    }
                                }else if (item_type == 'edge'){
                                    // 1删除连线
                                    if (title === '1'){
                                        // console.log("删除线: ", clicked_model);
                                        let graph = self.getCurrentGraph();
                                        if (graph){
                                            const source = clicked_model.source;
                                            const target = clicked_model.target;
                                            // console.log(source, target);
                                            if (parseInt(source) && parseInt(target)){
                                                // 请求接口
                                                deleteMatchUpLine({source_id: source, target_id: target}).then(response => {
                                                    if (response.data.code === 0) {
                                                        self.$Message.success('删除成功');
                                                    }else {
                                                        self.$Message.error(response.data.msg);
                                                        return
                                                    }
                                                })
                                            }
                                            var all_edges = graph.getEdges();
                                            // console.log(all_edges);
                                            all_edges.forEach(function(edge,index){
                                                if ( ! edge.desyroyed ){
                                                    var edge_conf = edge._cfg;
                                                    // console.log(edge_conf);
                                                    if (edge_conf){
                                                        var edge_model = edge_conf.model;
                                                        if (edge_model.source === source && edge_model.target === target){
                                                            // self.graph.refresh(); // 报错
                                                            graph.removeItem( edge );
                                                        }
                                                    }
                                                }
                                            })
                                        }
                                    }
                                }
                            }else{
                                // 点击空白区域
                                if (title === '1'){
                                    // 新增轮次
                                    self.edit_bracket_id = parseInt(self.bracket_id);
                                    self.edit_group_id = parseInt(self.group_id);
                                    self.edit_round_id = 0;
                                    self.edit_round_drawer = true;
                                }
                            }
                        },
                        // offsetX and offsetY include the padding of the parent container
                        // 需要加上父级容器的 padding-left 16 与自身偏移量 10
                        offsetX: 0,
                        // 需要加上父级容器的 padding-top 24 、画布兄弟元素高度、与自身偏移量 10
                        offsetY: 0,
                        // the types of items that allow the menu show up
                        // 在哪些类型的元素上响应
                        itemTypes: ['node', 'edge', 'canvas'],  // , 'canvas'
                    });
                    // let minimap = new G6.Minimap({
                    //     size: [120, 80],
                    //     className: 'minimap',
                    //     type: 'delegate',
                    // });
                    let group_id = group.id;
                    if ( ! self.group_id ){
                        self.group_id = String (group_id);
                    }
                    let container_id = self.createNodeId(bracket_id, group_id);
                    //获取高度
                    let dom_element = document.getElementById(container_id);
                    if (dom_element){
                        var h = dom_element.clientHeight;
                        //获取宽度
                        var w = dom_element.clientWidth;
                        // console.log(container_id, w, h);
                        if (self.graphs[container_id]){
                            try{
                                self.graphs[container_id].destroy();
                            }catch{
                            }
                        }
                        self.graphs[container_id] = new G6.Graph({
                            container: container_id,  // String | HTMLElement，必须，在 Step 1 中创建的容器 id 或容器本身
                            width: w,              // Number，必须，图的宽度
                            height: h,              // Number，必须，图的高度
                            autoPaint: true,
                            fitView: true,
                            modes: {
                                default: [ "drag-canvas", "click-select", 'zoom-canvas',
                                    // 拖拽创建连线
                                    {
                                        type: 'create-edge',
                                        trigger: 'click', // 'click' by default. options: 'drag', 'click'
                                        key: 'shift',
                                    },
                                ]
                            },
                            defaultNode: {
                                type: "match-node"
                            },
                            defaultEdge: {
                                // type: 'polyline',
                                type: 'line-arrow',
                                /* you can configure the global edge style as following lines */
                                style: {
                                    stroke: '#9ea7b4',
                                    endArrow: {
                                        path: 'M 0,0 L 12,6 L 9,0 L 12,-6 Z',
                                        fill: '#FFF',
                                    },
                                },
                            },
                            plugins: [contextMenu],
                            // 节点不同状态下的样式集合
                            nodeStateStyles: {
                                // // 鼠标 hover 上节点，即 hover 状态为 true 时的样式
                                // hover: {
                                //     fill: 'lightsteelblue',
                                //     stroke: '#000',
                                //     lineWidth: 3,
                                // },
                                // // 鼠标点击节点，即 click 状态为 true 时的样式
                                // click: {
                                //     stroke: '#000',
                                //     lineWidth: 3,
                                // },
                                highlight: {
                                    opacity: 1,
                                },
                                dark: {
                                    opacity: 0.2,
                                }
                            },
                            // 边不同状态下的样式集合
                            edgeStateStyles: {
                                highlight: {
                                    stroke: '#E00',
                                    lineWidth: 3,
                                }
                            },
                        });
                        self.graphs[container_id].on('aftercreateedge', e => {
                            // self.calcNewCoor();
                            // 创建边之后
                            // console.log("创建边", e.edge);
                            // let edge_model = e.edge._cfg.model;
                            // 判断朝左 还是朝右
                            let source_node = e.edge._cfg.source;
                            let target_node = e.edge._cfg.target;
                            let source_node_type = source_node._cfg.model.node_type;
                            let target_node_type = target_node._cfg.model.node_type;
                            if (source_node_type === 2 && target_node_type === 2){
                                // 节点对应节点
                                let source_node_x = source_node._cfg.bboxCache.x;
                                let target_node_x = target_node._cfg.bboxCache.x;
                                let source_node_id = source_node._cfg.model.id;
                                let target_node_id = target_node._cfg.model.id;
                                // console.log(target_node_x, source_node_x);
                                if (target_node_x < source_node_x){
                                    // 从右往左
                                    self.graphs[container_id].updateItem(e.edge, {'sourceAnchor': 0, 'targetAnchor': 1,})
                                }else{
                                    // 从左往右
                                    self.graphs[container_id].updateItem(e.edge, {'sourceAnchor': 1, 'targetAnchor': 0,})
                                }
                                if (source_node_id !== target_node_id){
                                    addMatchUpLine({source_id: source_node_id, target_id: target_node_id}).then(response => {
                                        if (response.data.code === 0) {
                                            self.$Message.success('创建成功');
                                        }else {
                                            self.$Message.error(response.data.msg);
                                        }
                                    })
                                }
                            }else{
                                self.$Message.error("仅允许在比赛节点之间创建边");
                                // 删除边
                                self.graphs[container_id].hideItem(e.edge);
                                // self.graphs[container_id].removeItem(e.edge);
                                // self.graphs[container_id].refresh();
                            }
                        });
                        // 点击节点
                        self.graphs[container_id].on('node:mouseenter', function(e) {
                            var item = e.item;
                            self.graphs[container_id].setAutoPaint(false);
                            self.graphs[container_id].getNodes().forEach(function(node) {
                                self.graphs[container_id].clearItemStates(node);
                                self.graphs[container_id].setItemState(node, 'dark', true);
                            });
                            self.graphs[container_id].setItemState(item, 'dark', false);
                            self.graphs[container_id].setItemState(item, 'highlight', true);
                            self.graphs[container_id].getEdges().forEach(function(edge) {
                                if (edge.getSource() === item) {
                                    self.graphs[container_id].setItemState(edge.getTarget(), 'dark', false);
                                    self.graphs[container_id].setItemState(edge.getTarget(), 'highlight', true);
                                    self.graphs[container_id].setItemState(edge, 'highlight', true);
                                    edge.toFront();
                                } else if (edge.getTarget() === item) {
                                    self.graphs[container_id].setItemState(edge.getSource(), 'dark', false);
                                    self.graphs[container_id].setItemState(edge.getSource(), 'highlight', true);
                                    self.graphs[container_id].setItemState(edge, 'highlight', true);
                                    edge.toFront();
                                } else {
                                    self.graphs[container_id].setItemState(edge, 'highlight', false);
                                }
                            });
                            self.graphs[container_id].paint();
                            self.graphs[container_id].setAutoPaint(true);
                        });
                        self.graphs[container_id].on('node:dblclick', (e) => {
                            // 先将所有当前是 click 状态的节点置为非 click 状态
                            const item = e.item;
                            // self.edit_match =  JSON.parse(JSON.stringify( item._cfg.model ));
                            // self.edit_modal = true;
                            let model = item._cfg.model;
                            self.focused_item_id = model.id;
                            if (model.node_type === 1){
                                self.edit_round_drawer = true;
                                let _id = parseInt( model.id.slice(2) )
                                if (_id < 0){
                                    _id = -1 * _id
                                }
                                self.edit_bracket_id = parseInt(self.bracket_id);
                                self.edit_group_id = parseInt(self.group_id);
                                self.edit_round_id = _id;
                            }else{
                                // alert("比赛: "+model.id);
                                let _id = parseInt( model.id )
                                self.edit_matchup_id = _id;
                                self.edit_matchup_drawer = true;
                            }
                        });
                        // 
                        var graph_data = {
                            nodes: group.nodes,
                            edges: group.edges,
                        }
                        self.graphs[container_id].data(graph_data);  // 读取 Step 2 中的数据源到图上
                        self.graphs[container_id].render();    // 渲染图
                    }
                });
            }
        },
        // end
        closeRoundEditPage(value){
            let self= this;
            if (value){
                let round_id = value.id;
                let _ids = [round_id, -1 * round_id ]
                // 更新节点
                let graph = self.getCurrentGraph();
                if (graph){
                    graph.getNodes().forEach(function(node,index){
                        if ( ! node.desyroyed ){
                            var node_conf = node._cfg;
                            if (node_conf ){
                                var node_model = node_conf.model;
                                let node_id = parseInt( node_model.id.slice(2) )
                                if ( node_model.node_type === 1 && _ids.indexOf( node_id ) > -1 ){
                                    // console.log(1,1 , node_model);
                                    node_model.name = value.name;
                                    node_model.abbr = value.short_name;
                                    node_model.long_name = value.long_name;
                                    node_model.start_time = value.first_match_time;
                                    node_model.end_time = value.last_match_time;
                                    node_model.number = value.number;
                                    node_model.deleted = value.deleted;
                                    graph.updateItem( node_model.id, node_model);
                                }
                            }
                        }
                    })
                }
            }else{
                // 重新获取数据
                self.bracket_id = null;
                self.group_id = null;
                self.getData();
            }
            self.edit_round_drawer = false;
            self.edit_round_id = 0;
        },
        // end
        closeMatchupEditPage(){
            let self= this;
            self.edit_matchup_drawer = false;
            self.edit_matchup_id = 0;
            // self.bracket_id = null;
            // self.group_id = null;
            self.getData();
        },
        // end
        closeBracketEditPage(new_data){
            let self= this;
            self.edit_bracket_drawer = false;
            if(new_data.id){
                // console.log(self.brackets)
                let element = self.brackets[new_data.id];
                if (element){
                    element['deleted'] = new_data.deleted;
                }
            }else{
                // 新增 - 重新请求数据
                self.getData();
            }
        } ,
        closeGroupEditPage(new_data){
            let self= this;
            self.edit_group_drawer = false;
            if(new_data.id){
                // console.log(self.brackets)
                let bracket = self.brackets[self.bracket_id];
                let groups = bracket.groups;
                if (groups){
                    groups.forEach(element => {
                        if (element.id === new_data.id){
                            element['deleted'] = new_data.deleted;
                        }
                    })
                }
            }else{
                // 新增 - 重新请求数据
                self.getData();
            }
        } ,
        // end 
        addNode(){
            let self = this;
            self.edit_match = JSON.parse(JSON.stringify({
                home: {},
                away: {},
                x: 0,
                y: 0,
                anchorPoints: [
                    [0, 0.5],
                    [1, 0.5],
                ],
                sequence_type: 1,
            }));
            self.edit_modal = true;
        },
        // end
        //     self.graph.addItem('node', self.edit_match);
        //     const current_nodes = self.graph.getNodes();
        //     var all_edges = self.graph.getEdges();
        // end
        lockedChanged(status){
            let self = this;
            // console.log(status);
            let locked = status? 1: 0;
            // 更新到线上
            let params = {
                sport_id: self.sport_id,
                season_id: self.season_id,
                locked: locked,
            }
            updateBracketLock(params).then(response => {
                if (response.data.code === 0) {
                    self.$Message.success('更新成功!');
                }else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end
    },
    //
    computed: {
    }
}
</script>
