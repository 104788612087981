<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Form label-position="left" :label-width="80" >
            <FormItem label="id: " v-if="data.id">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.id" disabled></Input>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="名称: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.name" :class="locks[String(type)+'-1-'+'name'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="中文名称: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.name_zh" :class="locks[String(type)+'-1-'+'name_zh'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="简称: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.abbr" :class="locks[String(type)+'-1-'+'abbr'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="中文简称: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.abbr_zh" :class="locks[String(type)+'-1-'+'abbr_zh'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                </Row>
            </FormItem>
            <!-- <FormItem label="全称: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.long_name" :class="locks[String(type)+'-1-'+'long_name'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                </Row>
            </FormItem> -->
            <FormItem label="开始时间: " >
                <Row>
                    <Col span="20" >
                        <DatePicker 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm" 
                            :class="locks[String(type) + '-1-' + 'start_time'] === 1? 'locked-input': '' "
                            style="width: 100%"
                            v-model="start_time"
                        >
                        </DatePicker>
                    </Col>
                    
                </Row>
            </FormItem>
            <FormItem label="结束时间: " >
                <Row>
                    <Col span="20" >
                        <DatePicker 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm" 
                            :class="locks[String(type) + '-1-' + 'end_time'] === 1? 'locked-input': '' "
                            style="width: 100%"
                            v-model="end_time"
                        >
                        </DatePicker>
                    </Col>
                    
                </Row>
            </FormItem>

            <FormItem label="阶段: ">
                <Row v-for="(stage, index) in data.stages" :key="index" >
                    <Col span="16" >
                        <tournamentSearch v-model="data.stages[index]" :sport_id="sport_id" :season_id="data.stage_season_id" ></tournamentSearch>
                    </Col>
                    <Col span="2" >
                    </Col>
                    <Col span="4" >
                        <Icon type="md-close-circle"  @click.native="data.stages.splice(index, 1)" />
                    </Col>
                </Row>
                <Row>
                    <Col span="20" >
                        <Button shape="circle" icon="md-add" @click="data.stages.push({id:0, name:''})" ></Button>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="序号: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.number" :class="locks[String(type)+'-1-'+'number'] === 1? 'locked-input': '' " ></Input>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="状态: ">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.deleted" :class="locks[String(type) + '-1-' + 'deleted'] === 1? 'locked-select': '' ">
                            <Option :value="1" key="1">隐藏</Option>
                            <Option :value="0" key="0">展示</Option>
                        </Select>
                    </Col>
                </Row>
            </FormItem>
        </Form>
        <Row  align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import tournamentSearch from '../tournament/search.vue';
import { getBracketRound, updateBracketRound } from '@/api/ballsports/base/season';
import moment from 'moment';

export default {
    name: "round-edit",
    props: {
        sport_id: Number,   // 运动id
        round_id: Number,   // 轮次id
        bracket_id: Number,   // 
        group_id: Number,   // 
    },
    components: {
        tournamentSearch
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 3,
            type: 1, 
            spinShow: false,
            data: {
                id: 0,
                start_time: parseInt( moment() / 1000 ),
                end_time: parseInt( moment() / 1000),
                deleted: 0,
                sport_id: self.sport_id,
                stages: [],
            },
            locks: {},
        }
    },
    methods: {
        getData () {
            let self = this;
            if (self.round_id && self.round_id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    sport_id: this.sport_id,
                    "id" : this.round_id,
                }
                getBracketRound( params ).then(response => {
                    // console.log(response)
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                        }else{
                            this.data = {}
                        }
                        self.spinShow = false
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                // alert(1)
                self.data = {
                    id: 0,
                    start_time: parseInt( moment() / 1000 ),
                    end_time: parseInt( moment() / 1000),
                    deleted: 0,
                    sport_id: self.sport_id,
                    stages: [],
                    bracket_id: self.bracket_id,
                    group_id: self.group_id,
                }
            }
            
        },
        // end 
        emit(params){
            this.$emit('closePage', params)
        },

        save() {
            let self = this;
            // console.log(self.data)
            let stage_ids = [];
            self.data.stages.forEach(element => {
                if ( element.id > 0 && stage_ids.indexOf(element.id) === -1 ){
                    stage_ids.push( element.id )
                }
            });
            let params = {
                id: self.data.id,
                sport_id: self.sport_id,
                bracket_id: self.data.bracket_id || self.bracket_id,
                group_id: self.data.group_id || self.group_id,
                name: self.data.name,
                short_name: self.data.abbr,
                name_zh: self.data.name_zh,
                short_name_zh: self.data.abbr_zh,
                // long_name : self.data.long_name,
                first_match_time: self.data.start_time,
                last_match_time : self.data.end_time,
                number: self.data.number,
                stage_ids: stage_ids,
                deleted: self.data.deleted,
            };
            // console.log(params);
            updateBracketRound(params).then(response => {
                if (response.data.code == 0) {
                    self.emit(params);
                    self.$Message.success("保存成功")
                } else {
                    self.$Message.error(response.data.msg)
                }
            })
        },
    },
    mounted () {
        let self = this;
        // self.getData();
    },
    watch: {
        'round_id': {
            handler(value){
                // console.log("Detail Get Data", value);
                let self = this;
                self.getData();
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
    },
    computed: {
        start_time: {
            get: function () {
                return moment.unix(this.data.start_time).toDate();
            },
            set: function (newValue) {
                this.data.start_time = moment(newValue).unix();
            }
        },
        end_time: {
            get: function () {
                return moment.unix(this.data.end_time).toDate();
            },
            set: function (newValue) {
                this.data.end_time = moment(newValue).unix();
            }
        },
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>