<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Form label-position="left" :label-width="80" >
            <FormItem label="id: " v-if="data.id">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.id" disabled></Input>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="bracket_id" >
                <Row>
                    <Col span="20" >
                        <Input v-model="data.bracket_id" disabled></Input>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="序号: ">
                <Row>
                    <Col span="20" >
                        <InputNumber v-model="data.number" ></InputNumber>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="类型: ">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.type_id" >
                            <Option :value="0" key="0">未知</Option>
                            <Option :value="1" key="1">Winners bracket胜者区</Option>
                            <Option :value="2" key="2">Bronze final铜牌决赛</Option>
                        </Select>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="开始时间: " >
                <Row>
                    <Col span="20" >
                        <DatePicker 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm" 
                            :class="locks[String(type) + '-1-' + 'start_time'] === 1? 'locked-input': '' "
                            style="width: 100%"
                            v-model="start_time"
                        >
                        </DatePicker>
                    </Col>
                    
                </Row>
            </FormItem>
            <FormItem label="结束时间: " >
                <Row>
                    <Col span="20" >
                        <DatePicker 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm" 
                            :class="locks[String(type) + '-1-' + 'end_time'] === 1? 'locked-input': '' "
                            style="width: 100%"
                            v-model="end_time"
                        >
                        </DatePicker>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="状态: ">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.deleted" :class="locks[String(type) + '-1-' + 'deleted'] === 1? 'locked-select': '' ">
                            <Option :value="1" key="1">隐藏</Option>
                            <Option :value="0" key="0">展示</Option>
                        </Select>
                    </Col>
                </Row>
            </FormItem>
        </Form>
        <Row  align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import { getGroup, updateGroup } from '@/api/ballsports/base/season';
import moment from 'moment';

export default {
    name: "group-edit",
    props: {
        sport_id: Number,   // 运动id
        group_id: Number,   // 轮次id
        bracket_id: Number,   // 轮次id
    },
    components: {
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 4,
            type: 1, 
            spinShow: false,
            data: {
                id: 0,
                start_time: parseInt( moment() / 1000 ),
                end_time: parseInt( moment() / 1000),
                deleted: 0,
                sport_id: self.sport_id,
            },
            locks: {},
        }
    },
    methods: {
        getData () {
            let self = this;
            if (self.group_id && self.group_id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    sport_id: this.sport_id,
                    "id" : this.group_id,
                }
                getGroup( params ).then(response => {
                    // console.log(response)
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                        }else{
                            this.data = {}
                        }
                        self.spinShow = false
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                // alert(1)
                // console.log(self.bracket_id);
                self.data = {
                    id: 0,
                    start_time: parseInt( moment() / 1000 ),
                    end_time: parseInt( moment() / 1000),
                    deleted: 0,
                    sport_id: self.sport_id,
                    bracket_id: self.bracket_id,
                }
            }
            
        },
        // end 
        emit(params){
            this.$emit('closePage', params)
        },

        save() {
            let self = this;
            // console.log(self.data)
            let params = {
                id: self.data.id,
                sport_id: self.sport_id,
                bracket_id: self.data.bracket_id || self.bracket_id || 0,
                first_match_time: self.data.start_time,
                last_match_time : self.data.end_time,
                number: self.data.number,
                type_id: self.data.type_id,
                deleted: self.data.deleted,
            };
            // console.log(params)
            updateGroup(params).then(response => {
                if (response.data.code == 0) {
                    self.emit(params);
                    self.$Message.success("保存成功")
                } else {
                    self.$Message.error(response.data.msg)
                }
            })
        },
    },
    mounted () {
        let self = this;
        // self.getData();
    },
    watch: {
        'group_id': {
            handler(value){
                // console.log("Detail Get Data", value);
                let self = this;
                self.getData();
            },
            // deep: true,
	        // immediate: true,  // 深度触发
        },
        'bracket_id': {
            handler(value){
                // console.log("Detail Get Data bracket_id: ", value);
                let self = this;
                if ( ! self.data.bracket_id ){
                    self.data.bracket_id =  value ;
                }
            },
            // deep: true,
	        // immediate: true,  // 深度触发
        },
    },
    computed: {
        start_time: {
            get: function () {
                return moment.unix(this.data.start_time).toDate();
            },
            set: function (newValue) {
                this.data.start_time = moment(newValue).unix();
            }
        },
        end_time: {
            get: function () {
                return moment.unix(this.data.end_time).toDate();
            },
            set: function (newValue) {
                this.data.end_time = moment(newValue).unix();
            }
        },
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>